import React, { useEffect, useState } from "react";
import axios from "axios";
import AwardItem from "./AwardItem";
const AwardContent = () => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [tab, setTab] = useState("tab01");
  useEffect(() => {
    const getList = async () => {
      setLoading(true);

      axios
        .get(`/api/cosplay2021/list/award`)
        .then((res) => {
          setLoading(false);
          if (res.data) {
            setList(res.data);
          }
        })
        .catch((err) => {
          setLoading(false);
          //console.log(err.message);
          //setError(err.message);
        });
    };
    getList();

    return () => {};
  }, []);

  return (
    <section className="sec5">
      <div id="tab-demo">
        <ul className="tab-title">
          <li
            onClick={() => setTab("tab01")}
            className={tab === "tab01" ? "active" : ""}
            style={{ cursor: "pointer" }}
          >
            <a className={tab === "tab01" ? "on" : ""}>一般組得獎名單</a>
          </li>
          <li
            onClick={() => setTab("tab02")}
            className={tab === "tab02" ? "active" : ""}
            style={{ cursor: "pointer" }}
          >
            <a className={tab === "tab02" ? "on" : ""}>創意組得獎名單</a>
          </li>
        </ul>
        {list.length > 0 && tab === "tab01" && (
          <div id="tab01" className="tab-inner">
            " "
            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "61765141cacc6049f5ce8b41"
                )[0]
              }
              judge1={
                "每張作品都構圖飽滿，給人以強烈的視覺衝擊，COSER非常好的還原了角色的氣質與神韻，動作表現力非常強。豐富的色彩和飽和的畫面充分體現出了第五人格題材作品的魅力，尤其是紅蝶，氣質與妝容渾然一體，素質非常優秀。"
              }
              judge2={
                "畫面表現很有氣氛，場景也找得不錯，角色詮釋的也很棒，有充份表現出在遊戲裡面監管人給玩家帶來的壓迫感"
              }
              award={1}
            />
            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "6191dc21cacc6049f5ce9503"
                )[0]
              }
              judge1={
                "這幾位選手實力非常強，不但有強大的服裝道具實力，拍攝和後期的技術也是可見一斑，顏值也是非常高，畫面整體來說都非常之豐富，技術流確實不一樣。仔細看一看場地選擇也處理的不錯，是一個在平時台北動漫活動場地旁，但卻不太容易被發現的點。每一張照片都非常有美式電影海報的感覺，小到每根髮絲的處理，大到人物素材和背景素材的環境融合，都體現了很高的水準，非常期待這幾位選手之後的更多佳作。"
              }
              judge2={
                "我還以為角色從遊戲裡面走出來了，角色詮釋的非常棒，場景也很符合角色"
              }
              award={2}
            />
            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "6193bc20cacc6049f5cec696"
                )[0]
              }
              award={3}
              judge1={
                "場景貼合人物實際，圖片無論是色彩的調和還是構圖都格外優秀，美中不足的姿勢就是姿勢略顯僵硬，表情有些不自然，其他都堪稱完美。"
              }
              judge2={
                "COSER的整體表象讓人非常府和調香師的氣質，畫面也有營造讓人迷幻的感覺，我個人覺得很符合技能忘憂之香的感覺"
              }
            />
            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "6176baa3cacc6049f5ce8b47"
                )[0]
              }
              award={4}
              judge1={
                "從創意到實現都相當精湛的作品，它讓我們看到COS不僅僅是對服裝的展示，更是對故事的敘述。"
              }
              judge2={
                "貝拉夫人這套皮膚在遊戲比較暗色風格裡面就有很強烈的雍容華貴的感覺，coser不管妝容還有畫面的表現都非常貼近遊戲內角色那種難以言喻的氣質"
              }
            />
          </div>
        )}
        {list.length > 0 && tab === "tab02" && (
          <div id="tab02" className="tab-inner">
            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "617aae8fcacc6049f5ce8bae"
                )[0]
              }
              award={1}
              judge1={
                "每一幅作品都充滿了精細和認真。銳利飽滿的畫面，迷醉的色調與作品本身搭配十分合適。尤其喜歡再既有服裝基礎去更改風格與設定，人物神態動作味道十足。除去本身就十分仔細的服裝道具妝面和令人讚嘆的顏值，我們更可以看到畫面的每一個邊緣角落的布景都能看出選手在創作過程中的用心，實景拍攝創作到這個程度必須強力推薦。"
              }
              judge2={
                "以今夜不再的整體下去的改動，保有原時裝的風格，妝容上的表現也非常地棒"
              }
            />

            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "6177e08dcacc6049f5ce8b5b"
                )[0]
              }
              award={2}
              judge1={
                "經典作品第五人格的經典角色紅蝶的詮釋，首先整體的感覺上非常具有上海灘的場面感，營造的氣氛感將人物襯托地非常到位。再說到道具和服裝上，服裝的改造超出想像的還原。而在人物還原上，紅蝶的冷酷邪魅表現得很好。"
              }
              judge2={
                "上海灘的風格呈現意外也很適合十三娘這個skin表現，比起原本比較民國風改成上海攤的風格也非常合適"
              }
            />

            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "61766d30cacc6049f5ce8b44"
                )[0]
              }
              award={3}
              judge1={
                "選手對小紅帽的演繹都非常到位，可塑性極高，除了高度還原角色之外，更花了很多心思在服裝與構圖上，融入自己對角色的理解，賦予新的內涵，讓她們更為立體、生動。"
              }
              judge2={
                "日系和服搭配的感覺有點像視覺系樂團的風格，巧妙的轉換了歌德系的小巧思整體非常棒"
              }
            />

            <AwardItem
              contestant={
                list.filter(
                  (coser) => coser._id === "61a07665cacc6049f5cf5a6d"
                )[0]
              }
              award={4}
              judge1={
                "第三張作品很有電影原片的感覺，唯一不足之處就是天空有些發空，感覺再自然點的效果會更好。服裝道具很有趣，感覺作品的視覺衝擊力還可以再強一些。"
              }
              judge2={
                "教堂整體色調還花心思調整一致了很棒"
              }
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default AwardContent;
