import React from "react";
import { Link, useHistory } from "react-router-dom";
const ApplySection = () => {
  let history = useHistory();
  return (
    <section className="sec1">
      <div className="apply_index">
        <div className="apply_nav">
          <div className="hover_area hover_area_p">
            <div className="apply_nav_item">
              <Link
                onClick={() => {
                  const signupBegin = new Date("2021-10-25 00:00:00");
                  const today = new Date();

                  if (today.getTime() < signupBegin.getTime()) {
                    window.alert("敬請期待，預計2021/10/25開始報名喔！");
                    return;
                  } else {
                    history.push("/cosplay2021/signup/pg");
                  }
                }}
                className="go_apply_btn1 apply_icons"
              >
                {" "}
              </Link>
            </div>
          </div>
          <div className="hover_area hover_area_t">
            <div className="apply_nav_item apply_nav_item_t">
              <Link
                onClick={() => {
                  const signupBegin = new Date("2021-10-25 00:00:00");
                  const today = new Date();

                  if (today.getTime() < signupBegin.getTime()) {
                    window.alert("敬請期待，預計2021/10/25開始報名喔！");
                    return;
                  } else {
                    history.push("/cosplay2021/signup/cg");
                  }
                }}
                className="go_apply_btn2 apply_icons"
              >
                {" "}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApplySection;
