import React from "react";
import { Link, useHistory } from "react-router-dom";
const Header = ({ match }) => {
  let history = useHistory();
  //console.log("header", match);

  //{match.params.category.toLowerCase() === "pg" ? (
  return (
    <header>
      <Link to="/cosplay2021">
        <img
          alt="報名"
          src="/cosplay2021/image/link.png"
          style={{ position: "relative", left: "47%", top: "10px" }}
        />{" "}
      </Link>
      <nav className="nav">
        <ul>
          <li className={match?.path.indexOf("signup") > -1 ? "open" : ""}>
            <Link to="/cosplay2021" id="#tab1">
                <p>10/25-11/30</p>
                <p>報名徵集階段</p>
            </Link>
          </li>
          <li className={match?.path.indexOf("showcase") > -1 ? "open" : ""}>
            <Link onClick={() => {
                const voteBegin = new Date("2021-11-15 00:00:00");
                const today = new Date();

                if (today.getTime() < voteBegin.getTime()) {
                  window.alert("敬請期待，預計2021/11/15開放投票喔！");
                  return;
                } else {
                  history.push("/cosplay2021/showcase/");
                }
              }}
              id="#tab2"
            >
              <p>11/15-12/05</p>
              <p>線上投票階段</p>
            </Link>
          </li>
          <li className={match?.path.indexOf("award") > -1 ? "open" : ""}>
            <Link onClick={() => {
                const awardBegin = new Date("2021-12-10 12:00:00");
                const today = new Date();
                console.log(awardBegin);
                console.log(today);
                if (today.getTime() < awardBegin.getTime()) {
                  window.alert("敬請期待，預計2021/12/10公告得獎名單喔！");
                  return;
                } else {
                  history.push("/cosplay2021/award");
                }
              }}
              id="#tab3"
            >
                <p>2021/12/10</p>
                <p>得獎公告</p>
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
