import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AwardContent from "../components/AwardContent";

const AwardScreen = ({ match }) => {
  let history = useHistory();
  const awardBegin = new Date("2021-12-10 12:00:00");
  const today = new Date();

  if (today.getTime() < awardBegin.getTime()) {
    history.push("/cosplay2021");
  }
  return (
    <>
      <Helmet>
        <link rel="stylesheet" type="text/css" href="css/award.css" />
      </Helmet>

      <Header match={match} />
      <AwardContent />

      <Footer />
    </>
  );
};

export default AwardScreen;
