import React from "react";

const EventIntroSection = () => {
  return (
    <section className="sec1-1">
      <p className="sec-title"></p>
      <div className="sec-box">
        <p className="sec1-title" style={{ fontSize: "18px" }}>
        在《第五人格》的神秘莊園中，每個人物都擁有自己獨特的背景故事和特色。在一場場追逐與逃生的競賽裡，陪伴你逃出生天、奪取勝利。現在，邀請各位偵探們一起發揮創意與想像力，化身你最愛的角色，重新演繹、賦予角色們新生命。在這場萬聖舞會中盡情地展現對角色的熱愛與創造力吧！
        </p>
        <p className="match">⇝競賽主題⇜</p>
        <p className="sec1-title">
        需以《第五人格》遊戲中的角色與劇情內容作為創作來源，自由延伸表達主題，不限作品創作風格及表現方式。
        <br /><span class="b">※不含IP聯動作品角色</span>
        </p>
        <p className="match">⇝活動時程⇜</p>
        <p className="sec1-title">
          報名徵集：2021/10/25~11/30 23:59
          <br />
          網路投票：2021/11/15~12/5 23:59
          <br />
          得獎公告：2021/12/10
          <br />
        </p>
        <p className="match">⇝競賽分組⇜</p>
        <p className="sec1-title">
          本次賽事分為「一般組」及「創意組」 進行徵稿，
          <span className="b">每位參賽者至多可於兩組別各投稿乙次，單一組別不可重複投稿。</span>
          <br />
          <span className="b">【一般組】</span>
          <br />
          1.不限任何Cosplay表現技法，需完整呈現遊戲中的角色時裝設定，並透過個人創意設計合適的場景、道具及構圖。
          <br />
          2.不限制單人或團體形式創作作品。若為多人、團隊拍攝作品只需代表人投稿參賽即可。
          <br />
          <span className="b">【創意組】</span>
          <br />
          1.不限任何Cosplay表現技法，請選擇一名求生者/監管者角色，並發揮對角色的熱愛與創意，為他/她設計一套新時裝。
          <br />
          2.可將遊戲原有之時裝變更設計、二創呈現，或重新設計一套原創時裝。但需注意，必須保有角色原有特徵，不得更改角色基本人設。
          <br />
          3.創意組僅限個人投稿參賽，不得以團體形式參加。
          <br />
          <span class="b">※為避免版權爭議，創作之服裝可為特定主題或風格，但不得結合其它IP商標或人物進行創作。</span>
        </p>
      </div>
      <div className="sec1-img-c">
        <img src="image/c.png" width="200px" alt="" />
      </div>
      <div className="sec1-img-d">
        <img src="image/d.png" width="200px" alt="" />
      </div>
    </section>
  );
};

export default EventIntroSection;
